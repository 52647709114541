import { Token, ZilChainId } from "@/types";

import vUsdt from "@/assets/img/tokens/vUsdt.svg";
import vZil from "@/assets/img/tokens/zil.svg";

import VZIL_TOKEN_ADDRESSES from "../../contracts/addresses/vZilTokens.json";

export const MAINNET_ZIL_TOKENS = {
  zil: {
    id: "zil",
    symbol: "ZIL",
    address: VZIL_TOKEN_ADDRESSES.zil[ZilChainId.MAINNET],
    decimals: 8,
    asset: vZil,
  } as Token,
  zusdt: {
    id: "zusdt",
    symbol: "zUSDT",
    address: VZIL_TOKEN_ADDRESSES.zusdt[ZilChainId.MAINNET],
    decimals: 8,
    asset: vUsdt,
  } as Token,
};
