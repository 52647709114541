import BigNumber from "bignumber.js";
import { TransactionCategory, TransactionEvent, Transaction } from "@/types";
import { TransactionResponse } from "./types";

const formatTransaction = ({
  amount,
  createdAt,
  updatedAt,
  category,
  event,
  iTokenAddress,
  timestamp,
  ...rest
}: TransactionResponse): Transaction => {
  return {
    ...rest,
    amountWei: new BigNumber(amount),
    createdAt: new Date(createdAt),
    updatedAt: new Date(updatedAt),
    category: category as TransactionCategory,
    event: event as TransactionEvent,
    iTokenAddress,
    timestamp: `${timestamp}`,
  };
};
export default formatTransaction;
