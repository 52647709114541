import sample from "lodash/sample";
import { ZilChainId } from "@/types";

import { BSC_SCAN_URLS } from "@/constants/bsc";
import { API_ENDPOINT_URLS, RPC_URLS } from "@/constants/endpoints";
import { getAssertedValue } from "@/utilities/envVariables";

export interface Config {
  chainId: ZilChainId;
  isOnTestnet: boolean;
  rpcUrl: string;
  apiUrl: string;
  indexerUrl: string;
  bscScanUrl: string;
}

const chainId: ZilChainId = parseInt(
  getAssertedValue(process.env.REACT_APP_CHAIN_ID, "REACT_APP_CHAIN_ID")
) as ZilChainId;

const isOnTestnet = chainId !== ZilChainId.MAINNET;
const rpcUrl = sample(RPC_URLS[chainId]) as string;
const apiUrl = API_ENDPOINT_URLS[chainId];
const bscScanUrl = BSC_SCAN_URLS[chainId];
const indexerUrl = {
  33101: "https://stg-api.zindex.zilliqa.com/zilliqa",
  32769: "https://api.zindex.zilliqa.com/zilliqa",
  32990: "",
};

const config: Config = {
  chainId,
  isOnTestnet,
  rpcUrl,
  apiUrl,
  indexerUrl: indexerUrl[chainId],
  bscScanUrl,
};

export default config;
