import { ZilChainId } from "@/types";

export const API_ENDPOINT_URLS = {
  [ZilChainId.MAINNET]: "https://api.venus.io/api",
  [ZilChainId.TESTNET]: "deprecated",
  [ZilChainId.LOCAL]: "deprecated",
};

export const RPC_URLS: {
  [key: string]: string[];
} = {
  [ZilChainId.MAINNET]: ["https://api.zilliqa.com"],
  [ZilChainId.TESTNET]: ["https://dev-api.zilliqa.com"],
  [ZilChainId.LOCAL]: ["http://localhost:5555"],
};
