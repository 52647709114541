import { InfinityWalletConnector } from "@infinitywallet/infinity-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import config from "@/config";
import { ZilChainId } from "@/types";

import { Connector } from "./types";

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [config.chainId],
});

const walletConnectConnector = new WalletConnectConnector({
  rpc: { [ZilChainId.MAINNET]: config.rpcUrl },
  chainId: ZilChainId.MAINNET,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

// const binanceChainWalletConnector = new BscConnector({ supportedChainIds: [config.chainId] });

const coinbaseWalletConnector = new WalletLinkConnector({
  url: config.rpcUrl,
  appName: "Venus",
});

const infinityWalletConnector = new InfinityWalletConnector({
  supportedChainIds: [ZilChainId.MAINNET],
});

export const connectorsByName = {
  [Connector.MetaMask]: injectedConnector,
  [Connector.BraveWallet]: injectedConnector,
  [Connector.WalletConnect]: walletConnectConnector,
  [Connector.CoinbaseWallet]: coinbaseWalletConnector,
  [Connector.TrustWallet]: injectedConnector,
  // [Connector.BinanceChainWallet]: binanceChainWalletConnector,
  [Connector.InfinityWallet]: infinityWalletConnector,
  [Connector.OperaWallet]: injectedConnector,
  [Connector.BitKeep]: injectedConnector,
};
