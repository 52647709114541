import { ApyChartProps } from "@/components";
import React from "react";
import { Token } from "@/types";
import {
  convertPercentageFromSmartContract,
  formatPercentage,
} from "@/utilities";

import { useGetMarketHistory } from "@/clients/api";
import BigNumber from "bignumber.js";

const useGetChartData = ({ vTokenId }: { vTokenId: Token["id"] }) => {
  const {
    data: marketSnapshotsData = {
      marketSnapshots: [],
    },
  } = useGetMarketHistory({
    vTokenId,
  });

  return React.useMemo(() => {
    const supplyChartData: ApyChartProps["data"] = [];
    const borrowChartData: ApyChartProps["data"] = [];

    [...marketSnapshotsData.marketSnapshots]
      // Snapshots are returned from earliest to oldest, so we reverse them to
      // pass them to the charts in the right order
      .reverse()
      .forEach((marketSnapshot) => {
        const timestampMs = new Date(marketSnapshot.createdAt).getTime();

        supplyChartData.push({
          apyPercentage: formatPercentage(
            convertPercentageFromSmartContract(marketSnapshot.supplyApy)
          ),
          timestampMs,
          balanceCents: new BigNumber(marketSnapshot.totalSupply),
        });

        borrowChartData.push({
          apyPercentage: formatPercentage(
            convertPercentageFromSmartContract(marketSnapshot.borrowApy)
          ),
          timestampMs,
          balanceCents: new BigNumber(marketSnapshot.totalBorrow),
        });
      });

    return {
      supplyChartData,
      borrowChartData,
    };
  }, [JSON.stringify(marketSnapshotsData?.marketSnapshots)]);
};

export default useGetChartData;
