import { TOKENS } from "@/constants/tokens";
import { VError } from "@/errors";
import { Market, MarketSnapshot } from "@/types";
import { indexerService } from "@/utilities/indexerService";
import BigNumber from "bignumber.js";

export interface GetMarketHistoryResponse {
  ioniseMarketHistory: {
    limit: number;
    total: number;
    marketSnapshots: Market[];
  };
}

export interface GetMarketHistoryInput {
  vTokenId: string;
  limit?: number;
  type?: string;
}

export type GetMarketHistoryOutput = {
  marketSnapshots: MarketSnapshot[];
};

function getVSymbolFromTokenId(tokenId: string) {
  switch (tokenId) {
    case "zil":
      return "iZIL";
    case "zusdt":
      return "izUSDT";
    default:
      throw new Error(`Unknown token id: ${tokenId}`);
  }
}

const getQuery = (input: GetMarketHistoryInput) => {
  return `
    ioniseMarketHistory(input: {
      marketSymbol: "${getVSymbolFromTokenId(input.vTokenId)}"
    }) {
      marketSnapshots {
        tokenPrice
        borrowRatePerBlock
        borrowApy
        totalBorrows
        totalBorrows2
        totalBorrowsUsd
        supplyApy
        supplyRatePerBlock
        totalSupply
        totalSupply2
        totalSupplyUsd
        cash
        liquidity
        collateralFactor
        exchangeRate
        reserveFactor
        blockNumber
      }
      total
      limit
    }
  `;
};

function unwrapVenusOraclePrice(
  tokenVenusOraclePrice: BigNumber,
  tokenDecimals: number
) {
  /**
   * unwrapping the multiplication from VenusChainlinkOracle.sol:getPrice()
   * ZIL price doesn't go this path but it is fine as zil has 1e18 decimals
   * so the decimal difference is 0
   * */
  const tokenAdjustor = new BigNumber(10).pow(
    new BigNumber(18).minus(tokenDecimals)
  );
  const tokenAdjustedPrice = new BigNumber(tokenVenusOraclePrice).dividedBy(
    tokenAdjustor
  );

  /**
   * Redoing the multiplication from VenusChainlinkOracle.sol:getChainlinkPrice()
   */
  const feedAdjustedPrice = tokenAdjustedPrice.dividedBy(1e18);

  return feedAdjustedPrice;
}

function getUSDValue(amount: string, tokenDecimals: number, price: BigNumber) {
  const nativeTokenAmount = new BigNumber(amount).dividedBy(
    new BigNumber(10).pow(tokenDecimals)
  );

  return nativeTokenAmount.multipliedBy(price);
}

const getMarketHistory = async (
  input: GetMarketHistoryInput
): Promise<GetMarketHistoryOutput> => {
  const response = await indexerService<GetMarketHistoryResponse>({
    query: getQuery(input),
  });

  // @todo Add specific api error handling
  if ("result" in response && response.result === "error") {
    throw new VError({
      type: "unexpected",
      code: "somethingWentWrong",
      data: { message: response.message },
    });
  }

  const underlyingTokenDecimals = (TOKENS as any)[input.vTokenId].decimals;

  const maxBlock =
    response.data?.data?.ioniseMarketHistory?.marketSnapshots?.reduce(
      (acc, curr) => Math.max(acc, curr.blockNumber),
      0
    );

  return {
    marketSnapshots: (
      response.data?.data?.ioniseMarketHistory?.marketSnapshots || []
    ).map((hm) => {
      const tokenPrice = unwrapVenusOraclePrice(
        hm.tokenPrice,
        underlyingTokenDecimals
      );

      const treasuryTotalBorrowsCents = getUSDValue(
        hm.totalBorrows,
        underlyingTokenDecimals,
        tokenPrice
      ).multipliedBy(1e2);

      const treasuryTotalSupplyCents = getUSDValue(
        hm.totalSupply2,
        underlyingTokenDecimals,
        tokenPrice
      ).dividedBy(1e8);

      // we don't have blocktimes so here we are estimating them, should be ok for visual purposes
      const blockDifference = maxBlock! - hm.blockNumber;
      const milisecondsBetweenBlocks = 1000 * 30;
      const chartDate = new Date(
        Date.now() - milisecondsBetweenBlocks * blockDifference
      );

      return {
        asset: hm.symbol,
        blockNumber: hm.blockNumber,
        blockTimestamp: 0,
        borrowApy: new BigNumber(hm.borrowApy).toString(),
        borrowVenusApy: "0",
        createdAt: "0",
        exchangeRate: hm.exchangeRate,
        id: `${hm.id}`,
        priceUSD: new BigNumber(hm.tokenPrice).toString(),
        supplyApy: new BigNumber(hm.supplyApy).toString(),
        supplyVenusApy: "0",
        totalBorrow: treasuryTotalBorrowsCents.toString(),
        totalSupply: treasuryTotalSupplyCents.toString(),
        updatedAt: chartDate.toISOString(),
      };
    }),
  };
};

export default getMarketHistory;
