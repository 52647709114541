import { MutationObserverOptions, useMutation } from "react-query";

import {
  RepayBnbInput,
  RepayBnbOutput,
  queryClient,
  repayBnb,
} from "@/clients/api";
import { useWeb3 } from "@/clients/web3";
import FunctionKey from "@/constants/functionKey";
import { ZIL_TOKENS } from "@/constants/tokens";

type Options = MutationObserverOptions<
  RepayBnbOutput,
  Error,
  Omit<RepayBnbInput, "web3">
>;

const useRepayNonBnbVToken = (options?: Options) => {
  const web3 = useWeb3();

  return useMutation(
    FunctionKey.REPAY_BNB,
    (params) =>
      repayBnb({
        web3,
        ...params,
      }),
    {
      ...options,
      onSuccess: (...onSuccessParams) => {
        const { fromAccountAddress } = onSuccessParams[1];

        queryClient.invalidateQueries(FunctionKey.GET_V_TOKEN_BALANCES_ALL);
        queryClient.invalidateQueries(FunctionKey.GET_MARKETS);
        queryClient.invalidateQueries([
          FunctionKey.GET_V_TOKEN_BORROW_BALANCE,
          {
            accountAddress: fromAccountAddress,
            vTokenId: ZIL_TOKENS.zil.id,
          },
        ]);

        if (options?.onSuccess) {
          options.onSuccess(...onSuccessParams);
        }
      },
    }
  );
};

export default useRepayNonBnbVToken;
