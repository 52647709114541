import config from "@/config";
import { set } from "lodash";

interface IndexerServiceInput {
  query: string;
}

export async function indexerService<D>({
  query,
}: IndexerServiceInput): Promise<
  | {
      status: number;
      data: { data: D; status: boolean } | undefined;
    }
  | {
      data: undefined;
      result: "error";
      message: string;
      status: boolean;
    }
> {
  const headers = {};

  set(headers, "Accept", "application/json");
  set(headers, "Content-Type", "application/json");

  return fetch(config.indexerUrl, {
    method: "POST",
    headers,
    body: JSON.stringify({ query: `{${query}}` }),
  })
    .then((response) =>
      response
        .json()
        .then((json) =>
          json
            ? { status: response.status, data: json }
            : { status: response.status, data: undefined }
        )
    )
    .catch((error) => ({
      status: false,
      data: undefined,
      result: "error",
      message: error,
    }));
}
