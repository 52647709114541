import BigNumber from "bignumber.js";
import { Market } from "@/types";
import { TOKENS, ZIL_TOKENS } from "@/constants/tokens";
import { indexerService } from "@/utilities/indexerService";

export interface GetMarketsResponse {
  ioniseMarkets: {
    dailyVenus: number;
    markets: Market[];
    request: { addresses: string[] };
    venusRate: string;
  };
}

export interface GetMarketsOutput {
  markets: Market[];
  dailyVenusWei: BigNumber | undefined;
}

const ioniseMarketsGraphqlQuery = `
  ioniseMarkets {
    dailyVenus
    markets {
      symbol
      tokenPrice
      borrowRatePerBlock
      borrowApy
      totalBorrows
      totalBorrows2
      totalBorrowsUsd
      supplyApy
      supplyRatePerBlock
      totalSupply
      totalSupply2
      totalSupplyUsd
      cash
      liquidity
      collateralFactor
      exchangeRate
      reserveFactor
    }
  }
`;

function unwrapVenusOraclePrice(
  tokenVenusOraclePrice: BigNumber,
  tokenDecimals: number
) {
  /**
   * unwrapping the multiplication from VenusChainlinkOracle.sol:getPrice()
   * ZIL price doesn't go this path but it is fine as zil has 1e18 decimals
   * so the decimal difference is 0
   * */
  const tokenAdjustor = new BigNumber(10).pow(
    new BigNumber(18).minus(tokenDecimals)
  );
  const tokenAdjustedPrice = new BigNumber(tokenVenusOraclePrice).dividedBy(
    tokenAdjustor
  );

  /**
   * Redoing the multiplication from VenusChainlinkOracle.sol:getChainlinkPrice()
   */
  const feedAdjustedPrice = tokenAdjustedPrice.dividedBy(1e18);

  return feedAdjustedPrice;
}

function getUSDValue(amount: string, tokenDecimals: number, price: BigNumber) {
  const nativeTokenAmount = new BigNumber(amount).dividedBy(
    new BigNumber(10).pow(tokenDecimals)
  );

  return nativeTokenAmount.multipliedBy(price);
}

const getMarkets = async (): Promise<GetMarketsOutput> => {
  const response = await indexerService<GetMarketsResponse>({
    query: ioniseMarketsGraphqlQuery,
  });

  if ("result" in response && response.result === "error") {
    throw new Error(response.message);
  }
  let markets: Market[] = [];
  let dailyVenusWei;
  if (response?.data?.data?.ioniseMarkets) {
    dailyVenusWei = new BigNumber(response.data.data.ioniseMarkets.dailyVenus);
    markets = Object.keys(ZIL_TOKENS).reduce<Market[]>(
      (acc: Market[], curr: string) => {
        const activeMarket = response.data?.data.ioniseMarkets.markets.find(
          (market: Market) =>
            market.symbol.toLowerCase().includes(curr.toLowerCase())
        );

        const underlyingTokenDecimals = (TOKENS as any)[curr].decimals;

        if (activeMarket) {
          const tokenPrice = unwrapVenusOraclePrice(
            activeMarket.tokenPrice,
            underlyingTokenDecimals
          );

          const treasuryTotalBorrowsCents = getUSDValue(
            activeMarket.totalBorrows,
            underlyingTokenDecimals,
            tokenPrice
          ).multipliedBy(1e2);

          const treasuryTotalSupplyCents = getUSDValue(
            activeMarket.totalSupply2,
            underlyingTokenDecimals,
            tokenPrice
          ).dividedBy(1e8);

          const formattedActiveMarket = {
            ...activeMarket,
            id: activeMarket.symbol.substring(1).toLowerCase(),
            tokenPrice,
            liquidity: treasuryTotalSupplyCents.minus(
              treasuryTotalBorrowsCents
            ),
            borrowVenusApy: new BigNumber(0),
            borrowApy: new BigNumber(activeMarket.borrowApy),
            supplyVenusApy: new BigNumber(0),
            supplyApy: new BigNumber(activeMarket.supplyApy),
            treasuryTotalBorrowsCents,
            treasuryTotalSupplyCents,
          };
          return [...acc, formattedActiveMarket];
        }
        return acc;
      },
      []
    );
  }

  return { markets, dailyVenusWei };
};

export default getMarkets;
