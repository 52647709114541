export enum Connector {
  MetaMask = "MetaMask",
  TrustWallet = "TrustWallet",
  WalletConnect = "WalletConnect",
  CoinbaseWallet = "CoinbaseWallet",
  BraveWallet = "BraveWallet",
  InfinityWallet = "InfinityWallet",
  OperaWallet = "OperaWallet",
  BitKeep = "BitKeep",
}
