import { Token } from "@/types";

import { VBEP_TOKENS, ZIL_TOKENS } from "@/constants/tokens";

const getVTokenByAddress = (address: string) => {
  let token: Token | undefined;

  Object.keys(ZIL_TOKENS)
    .filter((key) => Object.prototype.hasOwnProperty.call(ZIL_TOKENS, key))
    .forEach((tokenId) => {
      const currentToken = ZIL_TOKENS[tokenId as keyof typeof ZIL_TOKENS];
      if (currentToken?.address.toLowerCase() === address.toLowerCase()) {
        token = currentToken;
      }
    });

  return token;
};

export default getVTokenByAddress;
