import { t } from "@/translation";

export const unexpectedErrorPhrases = {
  somethingWentWrong: t("errors.somethingWentWrong"),
  somethingWentWrongRetrievingTransactions: t(
    "errors.somethingWentWrongRetrievingTransactions"
  ),
  somethingWentWrongRetrievingVoterAccounts: t(
    "errors.somethingWentWrongRetrievingVoterAccounts"
  ),
  somethingWentWrongRetrievingVoterDetails: t(
    "errors.somethingWentWrongRetrievingVoterDetails"
  ),
  somethingWentWrongRetrievingVoterHistory: t(
    "errors.somethingWentWrongRetrievingVoterHistory"
  ),
  walletNotConnected: t("errors.walletNotConnected"),
  undefinedAccountErrorMessage: t("errors.undefinedAccountErrorMessage"),
  internalErrorXvsToVrtConversionRatioUndefined: t(
    "convertVrt.internalErrorXvsToVrtConversionRatioUndefined"
  ),
  accountError: t("markets.errors.accountError"),
  incorrectSwapInput: t("swap.errors.incorrectSwapInput"),
};
