import BigNumber from "bignumber.js";
import { useMemo } from "react";
import { Market } from "@/types";
import { indexBy } from "@/utilities";
import config from "@/config";

import {
  IGetVTokenBalancesAllOutput,
  useGetMarkets,
  useGetVTokenBalancesAll,
} from "@/clients/api";
import { DEFAULT_REFETCH_INTERVAL_MS } from "@/constants/defaultRefetchInterval";
import { ZIL_TOKENS } from "@/constants/tokens";

const treasuries = {
  33101: "0x5eCAAddb3E21d34556aCCFeBd9F55AF884f5472E",
  32769: "0x70a2A4bDa90ae213E15a0472C86A2Ca304DFB3ef",
  32990: "",
};

export const treasuryAddress = treasuries[config.chainId];

export interface Data {
  treasuryTotalSupplyBalanceCents: BigNumber;
  treasuryTotalBorrowBalanceCents: BigNumber;
  treasuryTotalBalanceCents: BigNumber;
  treasuryTotalAvailableLiquidityBalanceCents: BigNumber;
}

export interface UseGetTreasuryTotalsOutput {
  isLoading: boolean;
  data: Data;
}

const vTokenAddresses = Object.values(ZIL_TOKENS).reduce(
  (acc, item) => (item.address ? [...acc, item.address] : acc),
  [] as string[]
);

const useGetTreasuryTotals = (): UseGetTreasuryTotalsOutput => {
  const {
    data: getMarketsData = {
      markets: [] as Market[],
    },
    isLoading: isGetMarketsLoading,
  } = useGetMarkets({
    placeholderData: {
      markets: [],
      dailyVenusWei: new BigNumber(0),
    },
  });

  console.log("Trying to reach treasury");

  const {
    data: vTokenBalancesTreasury = { balances: [] },
    isLoading: isGetVTokenBalancesTreasuryLoading,
  } = useGetVTokenBalancesAll(
    {
      account: treasuryAddress,
      vTokenAddresses,
    },
    {
      placeholderData: { balances: [] },
      refetchInterval: DEFAULT_REFETCH_INTERVAL_MS,
    }
  );

  const treasuryBalances = useMemo(
    () =>
      indexBy(
        (item: IGetVTokenBalancesAllOutput["balances"][number]) =>
          item.vToken.toLowerCase(), // index by vToken address
        vTokenBalancesTreasury.balances
      ),
    [JSON.stringify(vTokenBalancesTreasury)]
  );

  const { markets } = getMarketsData;
  const {
    treasuryTotalSupplyBalanceCents,
    treasuryTotalBorrowBalanceCents,
    treasuryTotalBalanceCents,
    treasuryTotalAvailableLiquidityBalanceCents,
  } = useMemo(() => {
    const data = markets.reduce(
      (acc, curr) => {
        let treasuryBalanceTokens = new BigNumber(0);
        if (treasuryBalances && treasuryBalances[curr.address]) {
          const mantissa = treasuryBalances[curr.address].tokenBalance;
          treasuryBalanceTokens = new BigNumber(mantissa).shiftedBy(
            -curr.underlyingDecimal
          );
        }

        acc.treasuryTotalBalanceCents = acc.treasuryTotalBalanceCents.plus(
          treasuryBalanceTokens.multipliedBy(curr.tokenPrice).times(100)
        );

        acc.treasuryTotalSupplyBalanceCents =
          acc.treasuryTotalSupplyBalanceCents.plus(
            curr.treasuryTotalSupplyCents
          );

        acc.treasuryTotalBorrowBalanceCents =
          acc.treasuryTotalBorrowBalanceCents.plus(
            curr.treasuryTotalBorrowsCents
          );

        acc.treasuryTotalAvailableLiquidityBalanceCents =
          acc.treasuryTotalAvailableLiquidityBalanceCents.plus(
            curr.liquidity.times(100)
          );

        return acc;
      },
      {
        treasuryTotalSupplyBalanceCents: new BigNumber(0),
        treasuryTotalBorrowBalanceCents: new BigNumber(0),
        treasuryTotalBalanceCents: new BigNumber(0),
        treasuryTotalAvailableLiquidityBalanceCents: new BigNumber(0),
      }
    );
    return data;
  }, [treasuryBalances, markets]);

  return {
    data: {
      treasuryTotalSupplyBalanceCents,
      treasuryTotalBorrowBalanceCents,
      treasuryTotalBalanceCents,
      treasuryTotalAvailableLiquidityBalanceCents,
    },
    isLoading: isGetVTokenBalancesTreasuryLoading || isGetMarketsLoading,
  };
};

export default useGetTreasuryTotals;
