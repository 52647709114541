export function getAssertedValue(
  value: string | undefined | null,
  name: string = ""
): string {
  if (value === undefined || value === null) {
    throw new Error(`${name ? name : "env variable"} missing`);
  }

  // clean the variable from possibly encoding hidden character
  return value.replace(/(\r\n|\n|\r)/gm, "");
}
