import { VError } from "@/errors";
import { Transaction, TransactionEvent } from "@/types";
import formatTransaction from "./formatTransaction";
import { TransactionResponse } from "./types";
import { indexerService } from "@/utilities/indexerService";

export interface GetTransactionsInput {
  page?: number;
  event?: TransactionEvent;
  address?: string;
  sort?: "desc" | "asc";
  order?:
    | "id"
    | "event"
    | "transactionHash"
    | "blockNumber"
    | "from"
    | "to"
    | "amount"
    | "createdAt";
}

export interface GetTransactionsResponse {
  ioniseTransactions: {
    limit: number;
    page: number;
    total: number;
    transactions: TransactionResponse[];
  };
}

export interface GetTransactionsOutput {
  limit: number;
  page: number;
  total: number;
  transactions: Transaction[];
}

const getQuery = (input: GetTransactionsInput) => `
  ioniseTransactions(input: {
    page: ${input.page || 0}
    ${input.address ? `address: "${input.address}"` : ""}
    ${input.event ? `event: "${input.event}"` : ""}
    order: "${input.order || "blockNumber"}"
    sort: "${input.sort || "desc"}"
  }) {
    transactions {
      amount
      blockNumber
      category
      createdAt
      event
      from
      id
      timestamp
      to
      transactionHash
      updatedAt
      iTokenAddress
    }
    page
    limit
    total
  }
`;

// TODO: convert to GraphQL
const getTransactions = async (
  params: GetTransactionsInput
): Promise<GetTransactionsOutput> => {
  const response = await indexerService<GetTransactionsResponse>({
    query: getQuery(params),
  });

  const payload = response.data?.data?.ioniseTransactions;
  // @todo Add specific api error handling
  if ("result" in response && response.result === "error") {
    throw new VError({
      type: "unexpected",
      code: "somethingWentWrong",
      data: { message: response.message },
    });
  }

  if (!payload) {
    throw new VError({
      type: "unexpected",
      code: "somethingWentWrongRetrievingTransactions",
    });
  }

  const { limit, page: payloadPage, total } = payload;
  const transactions = payload.transactions.map((data) =>
    formatTransaction(data)
  );
  return { limit, page: payloadPage, total, transactions };
};

export default getTransactions;
