import BigNumber from "bignumber.js";
import { checkForTokenTransactionError } from "@/errors";
import Web3 from "web3";
import type { TransactionReceipt } from "web3-core";

import { IZilToken } from "@/types/contracts";

export interface SupplyBnbInput {
  tokenContract: IZilToken;
  web3: Web3;
  account: string;
  amountWei: BigNumber;
}

export type SupplyBnbOutput = TransactionReceipt;

const supplyBnb = async ({
  web3,
  tokenContract,
  account,
  amountWei,
}: SupplyBnbInput): Promise<SupplyBnbOutput> => {
  const resp = await tokenContract.methods
    .mint()
    .send({ from: account, value: amountWei.toFixed() });

  return checkForTokenTransactionError(resp);
};

export default supplyBnb;
