import { css } from "@emotion/react";
import { useTheme } from "@mui/material";
import imgMark from "@/assets/img/IONISELogoFullGreyscaleLightStacked.svg";

export const useMyAccountStyles = () => {
  const theme = useTheme();
  return {
    container: css`
      width: 100%;
    `,
    row: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      flex-wrap: wrap;
    `,
    header: css`
      margin-bottom: ${theme.spacing(6)};
      padding: ${theme.spacing(2)} 0;
    `,
    apyWithXvs: css`
      display: flex;
      align-items: center;
    `,
    apyWithXvsLabel: css`
      margin-left: ${theme.spacing(2)};
    `,
    tooltip: css`
      display: flex;
    `,
    infoIcon: css`
      cursor: help;
    `,
    toggle: css`
      margin-left: ${theme.spacing(2)};
    `,
    labelListItem: css`
      display: block;
      margin-bottom: ${theme.spacing(2)};

      ${theme.breakpoints.down("sm")} {
        font-size: 12px;
      }
    `,
    inline: css`
      display: inline-block;
    `,
    bgImageSet: css`
      background-image: url(${imgMark});
      background-position: 145px 4px;
      background-repeat: no-repeat;
    `,
    netApyDiv: css`
      background-color: #1e2029;
      border-radius: 20px;
      padding: 50px;
      width: 25%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      ${theme.breakpoints.down("sm")} {
        width: 100%;
        padding: 30px;
        background-position: 38px -6px;
      }
      ${theme.breakpoints.down("md")} {
        width: 100%;
        padding: 30px;
        background-position: 52px -6px;
      }
      ${theme.breakpoints.down("lg")} {
        width: 100%;
        padding: 30px;
        background-position: 52px -6px;
      }
    `,
    netApyContainer: css`
      margin-bottom: ${theme.spacing(4)};
    `,
    netApy: css`
      display: flex;
      align-items: center;
      margin-bottom: ${theme.spacing(1)};
    `,
    netApyLabel: css`
      margin-right: ${theme.spacing(2)};
    `,
    list: css`
      padding-left: 0;
      display: flex;
      margin-bottom: ${theme.spacing(6)};

      ${theme.breakpoints.down("sm")} {
        flex-direction: column;
      }
    `,
    item: css`
      list-style: none;
      background-color: ${theme.palette.background.paper};
      padding-left: ${theme.spacing(8)};
      padding-right: ${theme.spacing(8)};
      border-right: 1px solid ${theme.palette.secondary.light};

      ${theme.breakpoints.down("sm")} {
        border-right: none;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

        & + & {
          margin-top: ${theme.spacing(2)};
        }
      }

      :first-of-type {
        border-left: none;
        padding-left: 0;
      }
      :last-child {
        border-right: none;
        padding-right: 0;
      }
    `,
    myAccountAnalytics: css`
      padding-left: 20px;
      width: 75%;
      height: 100%;

      ${theme.breakpoints.down("sm")} {
        padding-left: 0px;
        width: 100%;
        margin-left: -10px;
        margin-top: 15px;
      }
    `,
    myAccountItem: css`
      list-style: none;
      background-color: #1e2029;
      margin: 5px,
      margin-left: ${theme.spacing(1)};
      padding-right: ${theme.spacing(8)};
      width: 32.5%;
      height: 100%;
      padding: 22px;
      border-radius: 15px;

      ${theme.breakpoints.down("sm")} {
        margin-left: -24px;
        width: 33.5%;
        padding: 15px 10px 11px 12px;
      }
      
    `,
    inlineLabel: css`
      margin-right: ${theme.spacing(1)};
    `,
    progressBar: css`
      margin-bottom: ${theme.spacing(3)};
      margin-left: ${theme.spacing(11)};
      ${theme.breakpoints.down("sm")} {
        margin-left: ${theme.spacing(5)};
      }
    `,
    shieldIcon: css`
      margin-right: ${theme.spacing(2)};
    `,
    safeLimit: css`
      margin-right: ${theme.spacing(2)};
    `,
    bottom: css`
      display: flex;
      justify-content: flex-end;
      align-items: center;
    `,
  };
};
