import * as React from "react";
import { SVGProps } from "react";

const SvgDiscord = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.545 1.015A13.156 13.156 0 0 0 10.288.001a.05.05 0 0 0-.052.025c-.141.25-.297.578-.406.836a12.132 12.132 0 0 0-3.658 0A8.475 8.475 0 0 0 5.76.026.051.051 0 0 0 5.708 0a13.119 13.119 0 0 0-3.257 1.014.047.047 0 0 0-.021.019C.356 4.144-.213 7.18.066 10.177c.001.015.01.029.02.038a13.248 13.248 0 0 0 3.996 2.028.052.052 0 0 0 .056-.019c.308-.422.582-.867.818-1.335a.05.05 0 0 0-.028-.07 8.715 8.715 0 0 1-1.248-.598.052.052 0 0 1-.005-.085c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.2 5.454 1.2 8.041 0a.05.05 0 0 1 .053.006c.08.066.164.133.248.196a.052.052 0 0 1-.004.085 8.188 8.188 0 0 1-1.249.597.051.051 0 0 0-.027.071c.24.468.514.912.817 1.334a.05.05 0 0 0 .056.02 13.205 13.205 0 0 0 4.001-2.028.052.052 0 0 0 .021-.037c.334-3.465-.559-6.475-2.365-9.144a.04.04 0 0 0-.021-.019ZM5.347 8.352c-.789 0-1.438-.727-1.438-1.619s.637-1.62 1.438-1.62c.807 0 1.45.734 1.438 1.62 0 .892-.637 1.62-1.438 1.62Zm5.316 0c-.788 0-1.438-.727-1.438-1.619s.637-1.62 1.438-1.62c.807 0 1.45.734 1.438 1.62 0 .892-.63 1.62-1.438 1.62Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDiscord;
